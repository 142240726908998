<template>
  <div>
    <Loading :isActive="loading" />
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Dashboards">
        <DashboardTab
          :dashboards="dashboards"
          :widgets="widgets"
          :sistemas="sistemas"
          :tiposPerfis="tiposPerfis"
          :reload="loadDashboards"
        />
      </CTab>
      <CTab title="Widgets">
        <WidgetsTab />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import DashboardTab from './DashboardTab';
import WidgetsTab from './WidgetsTab';
import { get } from '../../helpers/apiRequest';

export default {
  name: 'Dashboards',
  components: {
    DashboardTab,
    WidgetsTab,
  },
  props: [
    'sistemas',
  ],
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      dashboards: [],
      widgets: [],
      tiposPerfis: [],
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_DASHBOARDS'
      ) || {
        descPlural: 'Dashboards',
        descSingular: 'Dashboard',
      };
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadDashboards() {
      get('/admin/dashboards').then((json) => (this.dashboards = json));
    },
    loadWidgets() {
      get('/admin/widgets').then((json) => (this.widgets = json));
    },
    loadPerfis() {
      get('/admin/perfis')
        .then((json) => {
          this.tiposPerfis = json;
        })
        .catch((err) => {});
    },
  },
  beforeMount() {
    this.loadDescricaoModulo();
    this.loadPerfis();
    this.loadDashboards();
    this.loadWidgets();
  },
};
</script>

<style scoped>
</style>
